import { default as indexpDtiGEFMIEMeta } from "/home/jsantos/workspace/src/github.com/projects/prospektus-website/pages/index.vue?macro=true";
export default [
  {
    name: indexpDtiGEFMIEMeta?.name ?? "index",
    path: indexpDtiGEFMIEMeta?.path ?? "/",
    meta: indexpDtiGEFMIEMeta || {},
    alias: indexpDtiGEFMIEMeta?.alias || [],
    redirect: indexpDtiGEFMIEMeta?.redirect,
    component: () => import("/home/jsantos/workspace/src/github.com/projects/prospektus-website/pages/index.vue").then(m => m.default || m)
  }
]