<script setup lang="ts">
import prospektus from "~/lib/data/prospektus.json";

useSeoMeta({
    title: prospektus.title,
    description: prospektus.description,
    ogTitle: "Prospectus Development Limitada",
    ogUrl: "https://www.prospektus.co.ao",
    ogSiteName: "Prospectus Development Limitada",
    ogType: "website",
    ogLocale: "pt_PT",
    ogDescription: prospektus.description,
    ogImage: "https://www.prospektus.co.ao/icons/favicon.ico",
    ogImageType: "image/png",
});

function ldJson() {
    return JSON.stringify({
        "@context": "https://schema.org",
        "@type": "website",
        name: "Prospectus Development Limitada",
        url: "https://www.prospektus.co.ao",
        sameAs: [
            prospektus.socials.facebook,
            prospektus.socials.linkedin,
            prospektus.socials.instagram,
            prospektus.socials.whatsapp,
        ],
    });
}

useHead({
    title: prospektus.title,
    meta: [
        {
            name: "keywords",
            content: prospektus.keywords,
        },
    ],
    script: [{ type: "application/ld+json", innerHTML: ldJson() }],
});
</script>
<template>
    <div>
        <NuxtPage />
    </div>
</template>
